import React from 'react';

import StandardPage from '../components/StandardPage';
import Head from '../components/Head';
// import { LevelOne, LevelTwo } from '../components/OrderedLists';

export default () => (
  <StandardPage>
    <Head
      title="Business Associate Agreement for Bagel"
      description="This is the Business Associate Agreement for all vendors and associates of Bagel Health, Inc."
      url="/baa/"
      image="/og_image.jpg"
    />
    <div className="section col ac jc bl60 pmargin">
      <div className="readable">
        <h2 className="title">Business Associate Agreement</h2>
        <h4 className="title">Bagel Health, Inc.</h4>
        <br />
        <p>
          By selecting or clicking on the “I Agree” button associated with this agreement, you and
          the company, institution or other entity (“Healthcare Entity”) employing, contracting or
          retaining you, or on whose behalf you are using the Hint Health Service described below
          (collectively, you and such Healthcare Entity, “you”, the “Covered Entity”) agree to be
          bound by and become a party to this Hint Health Business Associate Agreement (this
          "Agreement"). This Agreement is entered into between you and Hint Health Inc. ("us", “we”,
          the “Business Associate”), as of the date of such click or selection (the “Agreement
          Effective Date”). You represent, warrant and agree that you are authorized to enter into
          this Agreement on behalf of yourself and the Healthcare Entity and to bind yourself and
          the Healthcare Entity to the terms and conditions herein. If you do not agree to the terms
          and conditions of this Agreement or you are not authorized to bind the Healthcare Entity
          to this Agreement, then do not click or select “I Agree” and do not sign up for or use the
          Hint Health Service.
        </p>
        <p>
          This Agreement is being entered into in connection with your use of our online service for
          direct-pay, medical care providers, which may include, without limitation billing,
          membership management, reminder notifications and/or related functionality (the “Hint
          Health Service”) under the terms and conditions of that certain Hint Health Terms of
          Service Agreement (“TOS Agreement”) entered into between you and us. This Agreement,
          together with the TOS Agreement, as supplemented by this Agreement, (a) is intended by the
          parties as a final, complete and exclusive expression of the terms of their agreement
          regarding the subject matter hereof ; and (b) supersedes all prior agreements and
          understandings (whether oral or written) between the parties with respect to the subject
          matter hereof.
        </p>

        <p>The parties hereby agree as follows:</p>
        <h5 className="mt32">Definitions</h5>
        <ol className="ml32">
          <li>
            Business Associate: “Business Associate” shall generally have the same meaning as the
            term “business associate” at 45 CFR 160.103, and in reference to the party to this
            agreement, shall mean us.
          </li>

          <li>
            Covered Entity: “Covered Entity” shall generally have the same meaning as the term
            “covered entity” at 45 CFR 160.103, and in reference to the party to this agreement,
            shall mean you.
          </li>

          <li>
            HIPAA Rules: “HIPAA Rules” shall mean the Privacy, Security, Breach Notification, and
            Enforcement Rules at 45 CFR Part 160 and Part 164.
          </li>
        </ol>
        <p>
          The following terms used in this Agreement shall have the same meaning as those terms in
          the HIPAA Rules: Breach, Data Aggregation, Designated Record Set, Disclosure, Health Care
          Operations, Individual, Minimum Necessary, Notice of Privacy Practices, Protected Health
          Information, Required By Law, Secretary, Security Incident, Subcontractor, Unsecured
          Protected Health Information, and Use.
        </p>
        <h5 className="mt32">Our Obligations and Activities</h5>
        <p>We agree to:</p>
        <ol className="ml32">
          <li>
            Not use or disclose protected health information other than as permitted or required by
            this Agreement or as required by law;
          </li>

          <li>
            Use appropriate safeguards, and comply with Subpart C of 45 CFR Part 164 with respect to
            electronic protected health information, to prevent use or disclosure of protected
            health information other than as provided for by the Agreement or the Terms of Service;
          </li>

          <li>
            Within five (5) business days, report to you any use or disclosure of protected health
            information not provided for by the Agreement of which we become aware, including
            breaches of unsecured protected health information as required at 45 CFR 164.410, and
            any security incident of which we become aware;
          </li>

          <li>
            In accordance with 45 CFR 164.502(e)(1)(ii) and 164.308(b)(2), if applicable, ensure
            that any subcontractors that create, receive, maintain, or transmit protected health
            information on our behalf agree to the same restrictions, conditions, and requirements
            that apply to us with respect to such information;
          </li>

          <li>
            Make available to you protected health information in a designated record set as
            necessary to satisfy your obligations under 45 CFR 164.524;
          </li>

          <li>
            Make any amendments to protected health information in a designated record set as
            directed or agreed to by you pursuant to 45 CFR 164.526, or take other measures as
            necessary to satisfy your obligations under 45 CFR 164.526;
          </li>

          <li>
            Maintain and make available the information required to provide an accounting of
            disclosures to you as necessary to satisfy your obligations under 45 CFR 164.528;
          </li>

          <li>
            Comply with the requirements of Subpart E that apply to you in the performance of your
            obligations under Subpart E of 45 CFR Part 164, to the extent we are to carry out one or
            more of such obligations; and
          </li>

          <li>
            Make our internal practices, books, and records available to the Secretary for purposes
            of determining compliance with the HIPAA Rules.
          </li>
        </ol>
        <h5 className="mt32">Permitted Uses and Disclosures by Business Associate</h5>
        <ol className="ml32">
          <li>
            We shall only use or disclose protected health information as necessary to perform the
            services set forth in the TOS Agreement between the parties.
          </li>

          <li>We may use or disclose protected health information as required by law.</li>

          <li>
            We agree to make uses and disclosures and requests for protected health information
            consistent with your minimum necessary policies and procedures provided to us in writing
            in advance.
          </li>

          <li>
            We shall not use or disclose protected health information in a manner that would violate
            Subpart E of 45 CFR Part 164 if done by you, except for the specific uses and
            disclosures set forth below.
          </li>

          <li>
            We may use protected health information for our own proper managerial and administrative
            duties, or to carry out our legal responsibilities.
          </li>

          <li>
            We may disclose protected health information for our own proper managerial and
            administrative functions, or to carry out our legal responsibilities, provided the
            disclosures are required by law, or that we obtain reasonable assurances as governed by
            our Policies and Procedures from the person to whom the information is disclosed that
            the information will remain confidential and used or further disclosed only as required
            by law or for the purposes for which it was disclosed to the person, and the person
            notifies us of any instances of which it is aware in which the confidentiality of the
            information has been breached.
          </li>

          <li>We may provide data aggregation services relating to your health care operations.</li>
        </ol>
        <h5 className="mt32">
          Provisions for Covered Entity to Inform Business Associate of Privacy Practices and
          Restrictions
        </h5>
        <ol className="ml32">
          <li>
            You agree to notify us of any limitations in your notice of privacy practices under 45
            CFR 164.520, to the extent that such limitation may affect our use or disclosure of
            protected health information.
          </li>

          <li>
            You agree to notify us of any changes in, or revocation of, the permission by an
            individual to use or disclose his or her protected health information, to the extent
            that such changes may affect our use or disclosure of protected health information.
          </li>

          <li>
            You agree to notify us of any restriction on the use or disclosure of protected health
            information that you have agreed to or are required to abide by under 45 CFR 164.522, to
            the extent that such restriction may affect our use or disclosure of protected health
            information.
          </li>
        </ol>
        <h5 className="mt32">Permissible Requests by Covered Entity</h5>
        <p>
          You shall not request that we use or disclose protected health information in any manner
          that would not be permissible under Subpart E of 45 CFR Part 164 if done by you, except as
          specified in Section 3 of this Agreement.
        </p>
        <h5 className="mt32">Term and Termination</h5>
        <ol className="ml32">
          <li>
            <b>Term</b>: The Term of this Agreement shall be effective as of the Agreement Effective
            Date, and shall continue in perpetuity until either party terminates the agreement.
          </li>

          <li>
            <b>Termination</b>: Either party has the right to terminate this Agreement for any
            reason upon 90 days prior written notice to the other party.
          </li>

          <li>
            <p>
              <b>Obligations of Business Associate Upon Termination</b>:
            </p>
            <p>
              Upon termination of this Agreement for any reason, with respect to protected health
              information received from you, or created, maintained, or received by us on your
              behalf, we shall:
            </p>
            <ol type="a" className="ml32">
              <li>
                Retain only that protected health information which is necessary for us to continue
                to properly perform our own managerial and administrative duties, or to carry out
                our legal responsibilities;
              </li>

              <li>
                Destroy the remaining protected health information that we still maintain in any
                form;
              </li>

              <li>
                Continue to use appropriate safeguards and comply with Subpart C of 45 CFR Part 164
                with respect to electronic protected health information to prevent use or disclosure
                of the protected health information, other than as provided for in this Section, for
                as long as we retain the protected health information;
              </li>

              <li>
                Not use or disclose the protected health information we retain other than for the
                purposes for which such protected health information was originally retained, and
                subject to the conditions in Section 3 of this Agreement which applied prior to
                termination; and
              </li>

              <li>
                Destroy the protected health information we retain when it is no longer needed to
                properly perform our own managerial and administrative duties, or to carry out our
                legal responsibilities.
              </li>
            </ol>
          </li>
          <li>
            <b>Survival</b>: The obligations of the parties under this Section shall survive the
            termination of this Agreement.
          </li>
        </ol>
        <p className="small bl40 mt32">Updated 6 December, 2018</p>
      </div>
    </div>
  </StandardPage>
);
